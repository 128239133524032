import { ErrorType } from "../../../models/HttpError";
import { Settlement } from "../../../models/Entities/Settlements/Settlement";

// stores
import * as SettlementStore from '../../../redux/middleware/SettlementMiddleware';
import * as ContractStore from '../../../redux/middleware/ContractMiddleware';

//State
import { AppState } from "../../../redux/reducers";
import { FontWeights, IIconProps, IStackProps, getTheme, mergeStyleSets } from "@fluentui/react";
import { Contract } from "../../../models/Entities/Contracts/Contract";
import { Practitioner } from "../../../models/Entities/Practitioners/Practitioner";
import { Protocol } from "../../../models/Entities/Protocols/Protocol";
import { MedicalInsurance } from "../../../models/Entities/MedicalInsurances/MedicalInsurance";
import { MedicalInsuranceAgreement } from "../../../models/Entities/MedicalInsuranceAgreements/MedicalInsuranceAgreement";
import { MedicalProtocols } from "../../../redux/actions";
import moment from "moment";


interface IProps {
  isOpen: boolean;
  openPanel: () => void;
  dismissPanel: () => void;
  ContractId?: number;
  PractitionerId?: string;
  ProtocolId?: number;
  MedicalAgreementId?: number;
  MedicalInsuranceId?: number;
}
  
interface ConnectedProps {
  isAdding: boolean;
  isSuccessfullyAdded: boolean;
  failOnAdding: boolean;
  error: ErrorType | undefined;
  Contract: Contract | undefined;
  Practitioner: Practitioner | undefined;
  Protocol: Protocol | undefined;
  MedicalAgreement: MedicalInsuranceAgreement | undefined;
  MedicalInsurance: MedicalInsurance | undefined;
}

interface SettlementDispatchProps {
  AddAgreementSettlement: typeof SettlementStore.actionCreators.AddAgreementSettlement;
  AddProtocolSettlement: typeof SettlementStore.actionCreators.AddProtocolSettlement;
  AddPractitionerSettlement: typeof SettlementStore.actionCreators.AddPractitionerSettlement;
  GetContractById: typeof ContractStore.actionCreators.GetContractById;
  AssociateContractToProtocol: typeof ContractStore.actionCreators.AssociateContractToProtocol;
}

export type Props = IProps & ConnectedProps & SettlementDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {

  var Contract : Contract | undefined;

  if(ownProps.PractitionerId) {
    Contract = state.Contract?.list?.find((contract: Contract) => contract.practitioners[0]?.practitionerId === ownProps.PractitionerId);
  } 

  if(ownProps.ProtocolId) {
    Contract = state.Contract?.list?.find((contract: Contract) => contract.protocol[0]?.protocolId === ownProps.ProtocolId);
  }

  const ContractProtocolAssociated : number | undefined = state.Contract?.list?.find((contract: Contract) => contract?.id === ownProps.ContractId)?.protocol[0]?.protocolId;
  const ContractPractitionerAssociated : string | undefined = state.Contract?.list?.find((contract: Contract) => contract?.id === ownProps.ContractId)?.practitioners[0]?.practitionerId;

  return {
    isAdding: state.Settlement?.isAddingNewOne,
    isSuccessfullyAdded: state.Settlement?.successAddingNewOne,
    failOnAdding: state.Settlement?.failOnAddingNewOne,
    error: state.Settlement?.error,
    Contract: ownProps.ContractId ? state.Contract?.list?.find(c => c.id === ownProps.ContractId) : Contract,
    Practitioner: ownProps.PractitionerId ? state.Practitioner?.list?.find(p => p.userId === ownProps.PractitionerId) : (ownProps.ContractId && ContractPractitionerAssociated) ? state.Practitioner?.list?.find((practitioner: Practitioner) => practitioner.userId === ContractPractitionerAssociated)  : undefined,
    Protocol: ownProps.ProtocolId ? state.MedicalProtocols?.list?.find(p => p.id === ownProps.ProtocolId) : (ownProps.ContractId && ContractProtocolAssociated) ? state.MedicalProtocols?.list?.find((protocol: Protocol) => protocol.id === ContractProtocolAssociated) : undefined,
    MedicalAgreement: ownProps.MedicalAgreementId ? state.MedicalInsuranceAgreements?.list?.find(m => m.id === Number(ownProps.MedicalAgreementId)) : undefined,
    MedicalInsurance: ownProps.MedicalInsuranceId ? state.MedicalInsurances?.list?.find((medicalInsurance: MedicalInsurance) => medicalInsurance.id === Number(ownProps.MedicalInsuranceId)) : undefined
  }
};

export const mapDispatchToProps = {
  ...SettlementStore.actionCreators,
  ...ContractStore.actionCreators
};


// styles
export const buttonStyles = { root: { marginRight: 8, class: 'ConfirmAction' } };

export const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 }
  // styles: { root: { width: 150 } }
};

export const theme = getTheme();
export const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    borderRadius: 10
  },
  header: [
    theme.fonts.mediumPlus,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 12px 12px 12px',
      backgroundColor: 'lightgray',
      borderRadius: '10px 10px 0 0'
    }
  ],
  body: {
    flex: '4 4 auto',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 }
    }
  },
  footer: {
    flex: '4 4 auto',
    backgroundColor: 'white',
  }
});

export const cancelIcon: IIconProps = { iconName: 'Cancel' };

export const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px'
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
};


export const SuggestSettlementTitle = (entity: string): string => {
  return 'Liquidación ' + entity +  ' - ' +
    moment(new Date()).format('DD/MM/YYYY HH:mm');
}
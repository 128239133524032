import { SettlementState, SettlementUnloadState } from '../states/SettlementState';
import { Settlement } from '../../models/Entities/Settlements/Settlement';

// Settlement Reducer
import { Action, Reducer } from 'redux';

// actions
import * as Actions from '../actions';

// functions
import * as Functions from '../../functions/common';
import { SettlementDetail } from '../../models/Entities/SettlementDetails/SettlementDetail';
import { SettlementResume } from '../../models/Entities/SettlementResume/SettlementResume';
import { SettlementDetailConceptValue } from '../../models/Entities/SettlementDetailConceptValue/SettlementDetailConceptValue';



type KnownAction =
    | Actions.Settlements.Settlement_GetAll_Request_Action
    | Actions.Settlements.Settlement_GetAll_Receive_Action
    | Actions.Settlements.Settlement_GetAll_Fail_Action
    | Actions.Settlements.Settlement_UpdateAll_Request_Action
    | Actions.Settlements.Settlement_UpdateAll_Receive_Action
    | Actions.Settlements.Settlement_UpdateAll_Fail_Action
    | Actions.Settlements.Settlement_GetById_Request_Action
    | Actions.Settlements.Settlement_GetById_Receive_Action
    | Actions.Settlements.Settlement_GetById_Fail_Action
    | Actions.Settlements.Settlement_Add_Request_Action
    | Actions.Settlements.Settlement_Add_Receive_Action
    | Actions.Settlements.Settlement_Add_Fail_Action
    | Actions.Settlements.Settlement_Update_Request_Action
    | Actions.Settlements.Settlement_Update_Receive_Action
    | Actions.Settlements.Settlement_Update_Fail_Action
    | Actions.Settlements.Settlement_Delete_Request_Action
    | Actions.Settlements.Settlement_Delete_Receive_Action
    | Actions.Settlements.Settlement_Delete_Fail_Action
    | Actions.Settlements.Settlement_ChangeStatus_Request_Action
    | Actions.Settlements.Settlement_ChangeStatus_Receive_Action
    | Actions.Settlements.Settlement_ChangeStatus_Fail_Action
    | Actions.Settlements.Settlement_Recalculate_Request_Action
    | Actions.Settlements.Settlement_Recalculate_Receive_Action
    | Actions.Settlements.Settlement_Recalculate_Fail_Action
    | Actions.SettlementsResume.SettlementResume_GetAll_Receive_Action
    | Actions.SettlementsResume.SettlementResume_UpdateAll_Receive_Action
    | Actions.SettlementsResume.SettlementResume_Add_Receive_Action
    | Actions.SettlementsResume.SettlementResume_Update_Receive_Action
    | Actions.SettlementsResume.SettlementResume_Delete_Receive_Action
    | Actions.SettlementsDeatils.SettlementDetails_ResetStatus_Action
    | Actions.SettlementsDeatils.SettlementDetails_GetAll_Receive_Action
    | Actions.SettlementsDeatils.SettlementDetails_UpdateAll_Receive_Action
    | Actions.SettlementsDeatils.SettlementDetails_ChangeExcludeStatus_Receive_Action
    | Actions.SettlementsDeatils.SettlementDetails_ChangeMassExcludeStatus_Receive_Action
    | Actions.SettlementsDeatils.SettlementDetails_ChangeMarkOkStatus_Receive_Action
    | Actions.SettlementsDeatils.SettlementDetails_ChangeMarkForReviewStatus_Receive_Action
    | Actions.SettlementsDeatils.SettlementDetails_Add_Receive_Action
    | Actions.SettlementsDeatils.SettlementDetails_Update_Receive_Action
    | Actions.Settlements.Settlement_RefreshById_Request_Action
    | Actions.Settlements.Settlement_RefreshById_Receive_Action
    | Actions.Settlements.Settlement_RefreshById_Fail_Action;


export const SettlementReducer: Reducer<SettlementState> = (
    state: SettlementState | undefined, incomingAction: Action): SettlementState => {

    if (state === undefined) {
        return SettlementUnloadState as SettlementState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'SETTLEMENT_GETALL_REQUEST_ACTION':
            return {
                ...state,
                isLoadingAll: true,
                successLoadingAll: false,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'SETTLEMENT_GETALL_RECEIVE_ACTION':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: true,
                failOnLoadingAll: false,
                error: undefined,
                list: action.settlements
            };
        case 'SETTLEMENT_GETALL_FAIL_ACTION':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: false,
                failOnLoadingAll: true,
                error: action.error
            };
        case 'SETTLEMENT_UPDATEALL_REQUEST_ACTION':
            return {
                ...state,
                isLoadingAll: true,
                successLoadingAll: false,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'SETTLEMENT_UPDATEALL_RECEIVE_ACTION':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: true,
                failOnLoadingAll: false,
                error: undefined,
                list: action.settlements
            };
        case 'SETTLEMENT_UPDATEALL_FAIL_ACTION':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: false,
                failOnLoadingAll: true,
                error: action.error
            };
        case 'SETTLEMENT_GETBYID_REQUEST_ACTION':
            return {
                ...state,
                isLoadingOne: true,
                successLoadingOne: false,
                failOnLoadingOne: false,
                error: undefined
            };
        case 'SETTLEMENT_GETBYID_RECEIVE_ACTION':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: true,
                failOnLoadingOne: false,
                error: undefined,
                list: state.list ? [...state.list.filter((settlement: Settlement) => settlement.id !== action.settlement.id), action.settlement] : [action.settlement]
            };
        case 'SETTLEMENT_GETBYID_FAIL_ACTION':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: false,
                failOnLoadingOne: true,
                error: action.error
            };
        case 'SETTLEMENT_ADD_REQUEST_ACTION':
            return {
                ...state,
                isAddingNewOne: true,
                successAddingNewOne: false,
                failOnAddingNewOne: false,
                error: undefined
            };
        case 'SETTLEMENT_ADD_RECEIVE_ACTION':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: true,
                failOnAddingNewOne: false,
                error: undefined,
                list: [...state.list, action.settlement]
            };
        case 'SETTLEMENT_ADD_FAIL_ACTION':
            return {
                ...state,
                isAddingNewOne: false,
                successAddingNewOne: false,
                failOnAddingNewOne: true,
                error: action.error
            };
        case 'SETTLEMENT_UPDATE_REQUEST_ACTION':
            return {
                ...state,
                isUpdatingOne: true,
                successUpdatingOne: false,
                failOnUpdatingOne: false,
                error: undefined
            };
        case 'SETTLEMENT_UPDATE_RECEIVE_ACTION':
            return {
                ...state,
                isUpdatingOne: false,
                successUpdatingOne: true,
                failOnUpdatingOne: false,
                error: undefined,
                list: [...state.list.filter((settlement: Settlement) => settlement.id !== action.settlement.id), action.settlement]
            };
        case 'SETTLEMENT_UPDATE_FAIL_ACTION':
            return {
                ...state,
                isUpdatingOne: false,
                successUpdatingOne: false,
                failOnUpdatingOne: true,
                error: action.error
            };
        case 'SETTLEMENT_DELETE_REQUEST_ACTION':
            return {
                ...state,
                isDeletingOne: true,
                successDeletingOne: false,
                failOnDeletingOne: false,
                error: undefined
            };
        case 'SETTLEMENT_DELETE_RECEIVE_ACTION':
            return {
                ...state,
                isDeletingOne: false,
                successDeletingOne: true,
                failOnDeletingOne: false,
                error: undefined,
                list: [...state.list.filter((settlement: Settlement) => settlement.id !== action.settlementId)]
            };
        case 'SETTLEMENT_DELETE_FAIL_ACTION':
            return {
                ...state,
                isDeletingOne: false,
                successDeletingOne: false,
                failOnDeletingOne: true,
                error: action.error
            };
        case 'SETTLEMENT_RECALCULATE_REQUEST_ACTION':
            var settlementToRecalculate = state.list ? state.list.find((settlement: Settlement) => settlement.id === action.settlementId) : undefined;
            if(settlementToRecalculate) {
                settlementToRecalculate = {...settlementToRecalculate, isRecalculating: true, isRecalculatedSuccessfully: undefined, failOnRecalculating: false, error: undefined}
            }
            return {
                ...state,
                list: state.list ? settlementToRecalculate ? [...state.list?.filter((settlement: Settlement) => settlement.id !== action.settlementId), settlementToRecalculate] : state.list : state.list,
                error: undefined
            };
        case 'SETTLEMENT_RECALCULATE_RECEIVE_ACTION':
            var settlementRecalculated = {...action.settlement, isRecalculating: false, isRecalculatedSuccessfully: true, failOnRecalculating: false, error: undefined}
            return {
                ...state,
                list: state.list ? 
                    [...state.list?.filter((settlement: Settlement) => settlement.id !== action.settlement.id), settlementRecalculated] 
                        : state.list,
                error: undefined
            };
        case 'SETTLEMENT_RECALCULATE_FAIL_ACTION':
            var settlementToRecalculateFail = state.list ? state.list.find((settlement: Settlement) => settlement.id === action.settlementId) : undefined;
            if(settlementToRecalculateFail) {
                settlementToRecalculateFail = {...settlementToRecalculateFail, isRecalculating: false, isRecalculatedSuccessfully: false, failOnRecalculating: true, error: action.error}
            }
            return {
                ...state,
                list: state.list ? settlementToRecalculateFail ? [...state.list?.filter((settlement: Settlement) => settlement.id !== action.settlementId), settlementToRecalculateFail] : state.list : state.list,
            };
        case 'SETTLEMENT_CHANGESTATUS_REQUEST_ACTION':
            var settlementToChangeStatusRequest = state.list ? state.list.find((settlement: Settlement) => settlement.id === action.settlementId) : undefined;
            if(settlementToChangeStatusRequest) {
                settlementToChangeStatusRequest = {...settlementToChangeStatusRequest, isChangingStatus: true, successChangingStatus: undefined, failOnChangeStatus: false, error: undefined}
            }
            return {
                ...state,
                list: state.list ? settlementToChangeStatusRequest ? [...state.list?.filter((settlement: Settlement) => settlement.id !== action.settlementId), settlementToChangeStatusRequest] : state.list : state.list,
                error: undefined
            };
        case 'SETTLEMENT_CHANGESTATUS_RECEIVE_ACTION':
            var settlementChangedStatusReceive = state.list ? state.list.find((settlement: Settlement) => settlement.id === action.settlementId) : undefined;
            if(settlementChangedStatusReceive) {
                settlementChangedStatusReceive = {...settlementChangedStatusReceive, isChangingStatus: false, successChangingStatus: true, failOnChangeStatus: false, error: undefined, status: action.NewSettlementStatus}
            }
            return {
                ...state,
                list: state.list ? settlementChangedStatusReceive ? [...state.list.filter((settlement: Settlement) => settlement.id !== action.settlementId), settlementChangedStatusReceive] : state.list : state.list,
            };
        case 'SETTLEMENT_CHANGESTATUS_FAIL_ACTION':
            var settlementToChangeStatusFail = state.list ? state.list.find((settlement: Settlement) => settlement.id === action.settlementId) : undefined;
            if(settlementToChangeStatusFail) {
                settlementToChangeStatusFail = {...settlementToChangeStatusFail, isChangingStatus: false, successChangingStatus: false, failOnChangeStatus: true, error: action.error}
            }
            return {
                ...state,
                list: state.list ? settlementToChangeStatusFail ? [...state.list.filter((settlement: Settlement) => settlement.id !== action.settlementId), settlementToChangeStatusFail] : state.list : state.list,
            };
        case 'SETTLEMENT_REFRESHBYID_REQUEST_ACTION':
            var settlementToRefreshRequest = state.list ? state.list.find((settlement: Settlement) => settlement.id === action.settlementId) : undefined;
            if(settlementToRefreshRequest) {
                settlementToRefreshRequest = {...settlementToRefreshRequest, isRedreshing: true, isRefreshedSuccessfully: false, failOnRefreshing: false, error: undefined }
            }
            return {
                ...state,
                list: state.list ? settlementToRefreshRequest ? [...state.list?.filter((settlement: Settlement) => settlement.id !== action.settlementId), settlementToRefreshRequest] : state.list : state.list,
                error: undefined
            };
        case 'SETTLEMENT_REFRESHBYID_RECEIVE_ACTION':
            var settlementToRefreshReceive = state.list ? state.list.find((settlement: Settlement) => settlement.id === action.settlement.id) : undefined;
            if(settlementToRefreshReceive) {
                settlementToRefreshReceive = {...action.settlement, isRedreshing: false, isRefreshedSuccessfully: true, failOnRefreshing: false, error: undefined, details: settlementToRefreshReceive.details}
            }
            return {
                ...state,
                list: state.list ? [...state.list.filter((settlement: Settlement) => settlement.id !== action.settlement.id), action.settlement] : [action.settlement]
            };
        case 'SETTLEMENT_REFRESHBYID_FAIL_ACTION':
            var settlementToRefreshFail = state.list ? state.list.find((settlement: Settlement) => settlement.id === action.settlementId) : undefined;
            if(settlementToRefreshFail) {
                settlementToRefreshFail = {...settlementToRefreshFail, isRedreshing: false, isRefreshedSuccessfully: false, failOnRefreshing: true, error: action.error}
            }
            return {
                ...state,
                list: state.list ? settlementToRefreshFail ? [...state.list.filter((settlement: Settlement) => settlement.id !== action.settlementId), settlementToRefreshFail ]: state.list : state.list,
            };

        //#region SettlementResume
        
        case 'SETTLEMENTRESUME_GETALL_RECEIVE_ACTION':
            var settlement = state.list ? state.list.find((item: Settlement) => item.id === action.settlementId) : undefined;
            return {
                ...state,
                list: settlement ? [...state.list.filter((item: Settlement) => item.id !== settlement!.id), { ...settlement, resumes: action.settlementResumes }] : state.list
            };
        case 'SETTLEMENTRESUME_UPDATEALL_RECEIVE_ACTION':
            var settlement = state.list ? state.list.find((item: Settlement) => item.id === action.settlementId) : undefined;
            if(settlement) { settlement = { ...settlement, resumes: action.settlementResumes } };
            return {
                ...state,
                list: settlement ? [...state.list.filter((item: Settlement) => item.id !== settlement!.id), settlement] : state.list
            };
        case 'SETTLEMENTRESUME_ADD_RECEIVE_ACTION':
            var settlement = state.list ? state.list.find((item: Settlement) => item.id === action.settlementResume.settlementId) : undefined;
            return {
                ...state,
                list: settlement ? [...state.list.filter((item: Settlement) => item.id !== settlement!.id), { ...settlement, resumes: [...settlement!.resumes!, action.settlementResume] }].sort(Functions.DynamicSort('id')) : state.list
            };
        case 'SETTLEMENTRESUME_UPDATE_RECEIVE_ACTION':
            var settlement = state.list ? state.list.find((item: Settlement) => item.id === action.settlementResume.settlementId) : undefined;
            return {
                ...state,
                list: settlement ? [...state.list.filter((item: Settlement) => item.id !== settlement!.id), { ...settlement, resumes: [...settlement!.resumes!.filter((resume: SettlementResume) => resume.id !== action.settlementResume.id), action.settlementResume] }].sort(Functions.DynamicSort('id')) : state.list
            };
        case 'SETTLEMENTRESUME_DELETE_RECEIVE_ACTION':
            var settlement = state.list ? state.list.find((item: Settlement) => item.id === action.settlementId) : undefined;
            return {
                ...state,
                list: settlement ? [...state.list.filter((item: Settlement) => item.id !== settlement!.id), { ...settlement, resumes: [...settlement!.resumes!.filter((resume: SettlementResume) => resume.id !== action.settlementResumeId)] }].sort(Functions.DynamicSort('id')) : state.list
            };

        //#endregion SettlementResume

        //#region SettlementDetails

        case 'SETTLEMENTDETAILS_RESETSTATUS_ACTION':
            var settlementResetAction = state.list ? state.list.find((item: Settlement) => item.id === action.settlementId) : undefined;
            if (settlementResetAction && settlementResetAction.details) {
                settlementResetAction = { ...settlementResetAction, details: settlementResetAction.details?.map((detail: SettlementDetail) => detail.id === action.settlementDetailId ? { ...detail, isSaving: false, isSavedSuccessfully: false, failOnSaving: false, isUpdating: false, isUpdatedSuccessfully: false, failOnUpdating: false, isDeleting: false, isDeletedSuccessfully: false, failOnDeleting: false, isRedreshing: false, isRefreshedSuccessfully: false, failOnRefreshing: false, isCloning: false, isClonedSuccessfully: false, failOnCloning: false, error: undefined } : detail) }
            }
            return {
                ...state,
                list: settlementResetAction ? [...state.list.filter((item: Settlement) => item.id !== settlementResetAction!.id), settlementResetAction] : state.list
            };

        case 'SETTLEMENTDETAILS_GETALL_RECEIVE_ACTION':
            var settlement = state.list ? state.list.find((item: Settlement) => item.id === action.settlementId) : undefined;
            return {
                ...state,
                list: settlement ? [...state.list.filter((item: Settlement) => item.id !== settlement!.id), { ...settlement, details: action.settlementDetails.sort(Functions.DynamicSort('id')) }].sort(Functions.DynamicSort('id')) : state.list
            };
        case 'SETTLEMENTDETAILS_UPDATEALL_RECEIVE_ACTION':
            var settlement = state.list ? state.list.find((item: Settlement) => item.id === action.settlementId) : undefined;
            return {
                ...state,
                list: settlement ? [...state.list.filter((item: Settlement) => item.id !== settlement!.id), { ...settlement, details: action.settlementDetails }].sort(Functions.DynamicSort('id')) : state.list
            };
        case 'SETTLEMENTDETAILS_CHANGEEXCLUDESTATUS_RECEIVE_ACTION':
            var settlementExclude = state.list ? state.list.find((item: Settlement) => item.id === action.settlementDetail.settlementId) : undefined;
            
            if (settlementExclude) {

                var CurrentDetail = settlementExclude.details?.find((detail: SettlementDetail) => detail.id === action.settlementDetail.id);
                
                action.settlementDetail.conceptValue = CurrentDetail?.conceptValue;

                // ensure that the value is recalculated if concept is excluded
                if(action.settlementDetail.isExcluded === true) {
                    action.settlementDetail.conceptValue?.forEach(
                        (conceptValue: SettlementDetailConceptValue) => {
                            
                            // find settlement concept of value
                            var calculatedConcept = settlementExclude?.details?.filter(
                                (detail: SettlementDetail) => detail.conceptId === conceptValue.contractConceptId)?.forEach(
                                    (detail: SettlementDetail) => {
                                        detail.unitValue = detail.unitValue - conceptValue.value;
                                        detail.value = detail.value - conceptValue.value;
                                    }
                            );
                            conceptValue.value = 0;
                        }
    
                    );                     
                } else {
                    action.settlementDetail.conceptValue = action.settlementDetail.conceptValue?.map((conceptValue: SettlementDetailConceptValue) => {                      
                        var auxPercentValue = conceptValue.percentValue;
                        var newValue = (action.settlementDetail.value / 100) * auxPercentValue;

                        // find settlement concept of value
                        var calculatedConcept = settlementExclude?.details?.filter(
                            (detail: SettlementDetail) => detail.conceptId === conceptValue.contractConceptId)?.forEach(
                                (detail: SettlementDetail) => {
                                    detail.unitValue = detail.unitValue + newValue;
                                    detail.value = detail.value + newValue;
                                }
                        );

                        return {...conceptValue, value: newValue}
                    });
                }

                console.log(action.settlementDetail.conceptValue);

                settlementExclude = { 
                    ...settlementExclude, details: [...settlementExclude.details?.filter((detail: SettlementDetail) => 
                        detail.id !== action.settlementDetail.id), action.settlementDetail].sort(Functions.DynamicSort('id')) }

                if (settlementExclude) { settlementExclude = { ...settlementExclude, details: [...settlementExclude.details?.filter((detail: SettlementDetail) => detail.id !== action.settlementDetail.id), action.settlementDetail].sort(Functions.DynamicSort('id')) } };
            };
            
            
            return {
                ...state,
                list: settlementExclude ? [...state.list.filter((item: Settlement) => item.id !== settlementExclude!.id), settlementExclude].sort(Functions.DynamicSort('id')) : state.list
            };
        case 'SETTLEMENTDETAILS_CHANGEMASSEXCLUDESTATUS_RECEIVE_ACTION':
            var settlementMassExclude = state.list ? state.list.find((item: Settlement) => item.id === action.settlementId) : undefined;
            
            if (settlementMassExclude) {
                settlementMassExclude = {...settlementExclude!, details:
                    (settlementMassExclude.details || [])?.map((detail: SettlementDetail) => 
                        detail.settlementResumeId !== action.settlmenetResumeId && detail.isExcluded !== action.newStatus ? { ...detail, isExcluded: action.newStatus } : detail
                    )}
            };
            return {
                ...state,
                list: settlementExclude ? [...state.list.filter((item: Settlement) => item.id !== settlementExclude!.id), settlementExclude].sort(Functions.DynamicSort('id')) : state.list
            };
        case 'SETTLEMENTDETAILS_CHANGEMARKOKSTATUS_RECEIVE_ACTION':
            var settlementMarkOk = state.list ? state.list.find((item: Settlement) => item.id === action.settlementDetail.settlementId) : undefined;
            
            if (settlementMarkOk) {

                var CurrentDetail = settlementMarkOk.details?.find((detail: SettlementDetail) => detail.id === action.settlementDetail.id);
                
                action.settlementDetail.conceptValue = CurrentDetail?.conceptValue;

                action.settlementDetail.conceptValue = action.settlementDetail.conceptValue?.map((conceptValue: SettlementDetailConceptValue) => {

                    var auxPercentValue = conceptValue.percentValue;

                    return {...conceptValue, value: (action.settlementDetail.value / 100) * auxPercentValue}
                });

                settlementMarkOk = { 
                    ...settlementMarkOk, details: [...settlementMarkOk.details?.filter((detail: SettlementDetail) => 
                        detail.id !== action.settlementDetail.id), action.settlementDetail].sort(Functions.DynamicSort('id')) }

                if (settlementMarkOk) { settlementMarkOk = { ...settlementMarkOk, details: [...settlementMarkOk.details?.filter((detail: SettlementDetail) => detail.id !== action.settlementDetail.id), action.settlementDetail].sort(Functions.DynamicSort('id')) } };
            };

            return {
                ...state,
                list: settlementMarkOk ? [...state.list.filter((item: Settlement) => item.id !== settlementMarkOk!.id), settlementMarkOk].sort(Functions.DynamicSort('id')) : state.list
            };
        case 'SETTLEMENTDETAILS_CHANGEMARKFORREVIEWSTATUS_RECEIVE_ACTION':
            var settlementMarkForReview = state.list ? state.list.find((item: Settlement) => item.id === action.settlementDetail.settlementId) : undefined;
            
            if (settlementMarkForReview) {

                var CurrentDetail = settlementMarkForReview.details?.find((detail: SettlementDetail) => detail.id === action.settlementDetail.id);
                
                action.settlementDetail.conceptValue = CurrentDetail?.conceptValue;

                action.settlementDetail.conceptValue = action.settlementDetail.conceptValue?.map((conceptValue: SettlementDetailConceptValue) => {

                    var auxPercentValue = conceptValue.percentValue;

                    return {...conceptValue, value: (action.settlementDetail.value / 100) * auxPercentValue}
                });

                settlementMarkForReview = { 
                    ...settlementMarkForReview, details: [...settlementMarkForReview.details?.filter((detail: SettlementDetail) => 
                        detail.id !== action.settlementDetail.id), action.settlementDetail].sort(Functions.DynamicSort('id')) }

                if (settlementMarkForReview) { settlementMarkForReview = { ...settlementMarkForReview, details: [...settlementMarkForReview.details?.filter((detail: SettlementDetail) => detail.id !== action.settlementDetail.id), action.settlementDetail].sort(Functions.DynamicSort('id')) } };
            };
            
           
            return {
                ...state,
                list: settlementMarkForReview ? [...state.list.filter((item: Settlement) => item.id !== settlementMarkForReview!.id), settlementMarkForReview].sort(Functions.DynamicSort('id')) : state.list
            };
        case 'SETTLEMENTDETAILS_ADD_RECEIVE_ACTION':
            var settlementAddDetail = state.list ? state.list.find((item: Settlement) => item.id === action.settlementDetail.settlementId) : undefined;
            if (settlementAddDetail) { settlementAddDetail = { ...settlementAddDetail, details: [...settlementAddDetail.details!, action.settlementDetail]}};
            return {
                ...state,
                list: settlementAddDetail ? [...state.list.filter((item: Settlement) => item.id !== settlementAddDetail?.id), settlementAddDetail] : state.list
            };
        case 'SETTLEMENTDETAILS_UPDATE_RECEIVE_ACTION':
            var settlementUpdateDetail = state.list ? state.list.find((item: Settlement) => item.id === action.settlementDetail.settlementId) : undefined;
            if (settlementUpdateDetail) {

                var CurrentDetail = settlementUpdateDetail.details?.find((detail: SettlementDetail) => detail.id === action.settlementDetail.id);
                
                action.settlementDetail.conceptValue = CurrentDetail?.conceptValue;

                action.settlementDetail.conceptValue = action.settlementDetail.conceptValue?.map((conceptValue: SettlementDetailConceptValue) => {

                    var auxPercentValue = conceptValue.percentValue;

                    return {...conceptValue, value: (action.settlementDetail.value / 100) * auxPercentValue}
                });

                settlementUpdateDetail = { 
                    ...settlementUpdateDetail, details: [...settlementUpdateDetail.details?.filter((detail: SettlementDetail) => 
                        detail.id !== action.settlementDetail.id), action.settlementDetail].sort(Functions.DynamicSort('id')) }
            };

            return {
                ...state,
                list: settlementUpdateDetail ? [...state.list.filter((item: Settlement) => item.id !== settlementUpdateDetail?.id), settlementUpdateDetail] : state.list
            };

        //#endregion SettlementDetails

        default:
            return state;
    }
};


import { IResult } from '../models/HttpResult';
import { Protocol } from '../models/Entities/Protocols/Protocol';
import { ProtocolContract } from '../models/Entities/Protocols/ProtocolContracts';
import { ContractProtocolAssociation } from '../models/Entities/Contracts/ContractProtocolAssociation';

// Functions
import * as Functions from '../functions/common';

// Error Tracer
import * as Sentry from '@sentry/react';
import { ProtocolPatient } from '../models/Entities/Protocols/ProtocolPatient';
import { ProtocolPractitioner } from '../models/Entities/Protocols/ProtocolPractitioner';
import { ProtocolVisit } from '../models/Entities/Protocols/ProtocolVisit';
import { ProtocolPractitionerStudyTask } from '../models/Entities/Protocols/ProtocolPractitionerStudyTask';
import { PatientVisit } from '../models/Entities/Protocols/PatientVisist';
import { PatientVisitCount } from '../models/Entities/Protocols/PatientVisitCount';

const BaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'protocols/protocols/';
const ContractBaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'protocols/contracts/';
const VisitsURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'protocols/visits/';
const PatientsBaseURL = process.env.REACT_APP_BILLER_API_GATEWAY + 'settlements/protocol/';

// https://prebiller.hc.alpha-cr.com/apis/prebiller-gateway/settlements/protocol/17/patients?SearchText=I8F-MC-GPIJ-54333-56695

export const GetAllProtocols = async (token: string): Promise<Protocol[]> => {

  let RequestURL: string = BaseURL;

  try {
    const Response: Protocol[] = await fetch(RequestURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          Sentry.captureException(response);
          throw response;
        } else {
          return response.json() as Promise<IResult<Protocol[]>>;
        }
      })
      .then((data: IResult<Protocol[]>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const GetProtocolById = async (
  ProtocolId: number
, token: string): Promise<Protocol> => {
  const RequestURL: string = BaseURL + ProtocolId;

  try {
    const Response: Protocol = await fetch(RequestURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<Protocol>>;
        }
      })
      .then((data: IResult<Protocol>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

// export const AssociateContractToProtocol = async (
//   ProtocolId: number,
//   ContractId: number
// ) => {
//   const RequestURL: string =
//     BaseURL + ProtocolId + '/contracts/' + ContractId;

//   const token = await AuthorizeService.getAccessToken();

//   try {
//     const Response: Protocol = await fetch(RequestURL, {
//       method: 'POST',
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//         Authorization: 'Bearer ' + token
//       }
//     })
//       .then((response) => {
//         if (!response.ok) {
//           throw response;
//         } else {
//           return response.json() as Promise<IResult<Protocol>>;
//         }
//       })
//       .then((data: IResult<Protocol>) => {
//         return data.data;
//       })
//       .catch((error: any) =>
//         error.text().then((body: any) => {
//           throw Functions.HttpErrorHandler(body, error);
//         })
//       );

//     return Response;
//   } catch (error: any) {
//     Sentry.captureException(error);
//     throw error;
//   }
// };

export const AssociateContract = async (
  SponsorId: number,
  ContractId: number
, token: string): Promise<ProtocolContract> => {
  
  let RequestURL: string = ContractBaseURL + '/contracts';
  
  try {
    const Response: ProtocolContract = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'POST',
      body: JSON.stringify({"SponsorId": SponsorId, "ContractId": ContractId})
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<ProtocolContract>>;
        }
      })
      .then((data: IResult<ProtocolContract>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const DisassociateContract = async (
  ProtocolContractId: number
, token: string): Promise<any> => {
  
  let RequestURL: string = ContractBaseURL + '/contracts/' + ProtocolContractId;

  try {
    const Response: ProtocolContract = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'DELETE'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<any>>;
        }
      })
      .then((data: IResult<any>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const GetProtocolContracts = async (
  ProtocolId: number
, token: string): Promise<ContractProtocolAssociation> => {

  let RequestURL: string = ContractBaseURL + ProtocolId + '/contracts';
  try {
    const Response: ContractProtocolAssociation = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<ContractProtocolAssociation>>;
        }
      })
      .then((data: IResult<ContractProtocolAssociation>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const GetProtocolPatients = async(
  protocolId: number,
  searchText: string | null,
  token: string
) => {
  let RequestURL: string = BaseURL + protocolId + '/patients'; //(searchText && '/patients?SearchText=' + searchText);
  try {
    const Response: ProtocolPatient[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<ProtocolPatient[]>>;
        }
      })
      .then((data: IResult<ProtocolPatient[]>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
}; 

export const GetProtocolPatientById = async(
  protocolId: number,
  NumberInProtocol: string,
  token: string
) => {
  let RequestURL: string = PatientsBaseURL + protocolId + '/patients?SearchText=' + NumberInProtocol;

  try {
    const Response: ProtocolPatient = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<ProtocolPatient[]>>;
        }
      })
      .then((data: IResult<ProtocolPatient[]>) => {
        return data.data[0];
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const GetProtocolPractitioners = async(
  protocolId: number,
  token: string
) => {
  let RequestURL: string = BaseURL + protocolId + '/practitioners';
  
  try {
    const Response: ProtocolPractitioner[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<ProtocolPractitioner[]>>;
        }
      })
      .then((data: IResult<ProtocolPractitioner[]>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const GetProtocolPractitionersStudyTask = async(
  protocolId: number,
  token: string
) => {
  let RequestURL: string = BaseURL + protocolId + '/practitionersStudyTasks';

  try {
    const Response: ProtocolPractitionerStudyTask[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<ProtocolPractitionerStudyTask[]>>;
        }
      })
      .then((data: IResult<ProtocolPractitionerStudyTask[]>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const GetProtocolVisits = async(
  protocolId: number,
  token: string
) => {
  let RequestURL: string = BaseURL + protocolId + '/visits';	

  try {
    const Response: ProtocolVisit[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<ProtocolVisit[]>>;
        }
      })
      .then((data: IResult<ProtocolVisit[]>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

export const GetProtocolPatientVisits = async(
  protocolId: number,
  token: string
) => {
  let RequestURL: string = VisitsURL + 'protocol/' + protocolId;

  try {
    const Response: PatientVisit[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<PatientVisit[]>>;
        }
      })
      .then((data: IResult<PatientVisit[]>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};

//Get Practitioner protocol visits
export const GetPractitionerProtocolVisits = async(
  practitionerId: string,
  protocolId: number,
  dateFrom: Date,
  dateTo: Date,
  token: string
) => {
  let RequestURL: string = BaseURL + protocolId + '/practitioners/' + practitionerId + '/visits?DateFrom=' + dateFrom.toJSON() + '&DateTo=' + dateTo.toJSON();

  try {
    const Response: PatientVisit[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<PatientVisit[]>>;
        }
      })
      .then((data: IResult<PatientVisit[]>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
};


//Get Practitioner protocol visits
export const GetPractitionerProtocolVisitsCount = async(
  practitionerId: string,
  protocolsId: number[],
  dateFrom: Date,
  dateTo: Date,
  token: string
) => {
  
  var ProtocolIdArrayQueryString = protocolsId.map((id: number) => 'ProtocolsId=' + id).join('&');

  let RequestURL: string = BaseURL + 'practitioners/' + practitionerId + '/visits?DateFrom=' + dateFrom.toJSON() + '&DateTo=' + dateTo.toJSON() + '&' + ProtocolIdArrayQueryString;
  try {
    const Response: PatientVisitCount[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<PatientVisitCount[]>>;
        }
      })
      .then((data: IResult<PatientVisitCount[]>) => {
        return data.data;
      })
      .catch((error: any) => {
        console.log('error: ' + error);
        return error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        });
      });
    return Response;
  } catch (error: any) {
    console.log('error: ' + error);
    Sentry.captureException(error);
    throw error;
  }
};

export const GetProtocolByPractitionerId = async(
  practitionerId: string,
  token: string
) => {
  let RequestURL: string = BaseURL + '?PractitionerId=' + practitionerId;

  try {
    const Response: Protocol[] = await fetch(RequestURL, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      method: 'GET'
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.json() as Promise<IResult<Protocol[]>>;
        }
      })
      .then((data: IResult<Protocol[]>) => {
        return data.data;
      })
      .catch((error: any) =>
        error.text().then((body: any) => {
          throw Functions.HttpErrorHandler(body, error);
        })
      );

    return Response;
  } catch (error: any) {
    Sentry.captureException(error);
    throw error;
  }
}
import { IChoiceGroupOption, IDropdownOption } from "@fluentui/react";
import { Contract } from "../../../../models/Entities/Contracts/Contract";
import { ContractConcept } from "../../../../models/Entities/Contracts/ContractConcept";
import { ContractStage } from "../../../../models/Entities/Contracts/ContractStage";
import { ErrorType } from "../../../../models/HttpError";

// stores
import * as ContractConceptStore from '../../../../redux/middleware/ContractConceptMiddleware';
import * as ContractStore from '../../../../redux/middleware/ContractMiddleware';
import * as ContractConceptPaymentScheduleStore from '../../../../redux/middleware/ContractConceptPaymentScheduleMiddleware';
import * as ContractConceptConceptStore from '../../../../redux/middleware/ContractConceptConceptMiddleware';

//State
import { AppState } from "../../../../redux/reducers";
import { ContractConceptConcept } from "../../../../models/Entities/Contracts/ContractConceptConcept";


interface IProps {
  isOpen: boolean;
  openPanel: () => void;
  dismissPanel: () => void;
  ContractId: number;
  ContractAddendumId: number;
  ContractConceptId: number | undefined;
  CurrentConcept: ContractConcept[];
  CurrentStages: ContractStage[];
}

interface ConnectedProps {
  isContractLoaded: boolean;
  isConceptLoaded: boolean;
  ConceptsLoaded: boolean;
  isAdding: boolean;
  isAdded: boolean;
  isUpdated: boolean;
  isUpdating: boolean;
  failOnAdding: boolean;
  failOnUpdating: boolean;
  error: ErrorType | undefined;
  Contract: Contract;
  Concept: ContractConcept;
  Concepts: ContractConcept[] | undefined;
}

interface DispatchProps {
  GetContractConceptById: typeof ContractConceptStore.actionCreators.GetContractConceptById;
  AddContractConcept: typeof ContractConceptStore.actionCreators.AddContractConcept;
  UpdateContractConcept: typeof ContractConceptStore.actionCreators.UpdateContractConcept;
  GetContractById: typeof ContractStore.actionCreators.GetContractById;
  GetAllContractsConcept: typeof ContractConceptStore.actionCreators.GetAllContractsConcept;
  GetAllContractsConceptPaymentSchedule: typeof ContractConceptPaymentScheduleStore.actionCreators.GetAllContractsConceptPaymentSchedule;
  GetAllContractConceptConcepts: typeof ContractConceptConceptStore.actionCreators.GetAllContractConceptConcepts;
  AddContractConceptConcept: typeof ContractConceptConceptStore.actionCreators.AddContractConceptConcept;
  AddRangeContractConceptConcepts: typeof ContractConceptConceptStore.actionCreators.AddRangeContractConceptConcepts;
  AddContractConceptPaymenSchedule: typeof ContractConceptPaymentScheduleStore.actionCreators.AddContractConceptPaymenSchedule;
  DeleteContractConceptConcept: typeof ContractConceptConceptStore.actionCreators.DeleteContractConceptConcept;
  DeleteContractConceptPaymentSchedule: typeof ContractConceptPaymentScheduleStore.actionCreators.DeleteContractConceptPaymentSchedule;
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {

  const CurrentContract = state.Contract?.list?.find((item: Contract) => item.id === ownProps.ContractId ) || undefined;
  const CurrentAddendum = CurrentContract?.addendums?.find((item: any) => item.id === ownProps.ContractAddendumId) || undefined;
  const CurrentConcept = CurrentAddendum?.concepts?.find((item: any) => item.id === ownProps.ContractConceptId) || undefined;


  return{
    isContractLoaded: state.Contract?.successLoadingOne,
    isConceptLoaded: state.ContractConcept?.successLoadingOne,
    ConceptsLoaded: state.ContractConceptConcept?.successLoadingAll,
    isAdded: state.ContractConcept?.successAddingNewOne,
    isAdding: state.ContractConcept?.isAddingNewOne,
    isUpdated: state.ContractConcept?.successUpdatingOne,
    isUpdating: state.ContractConcept?.isUpdatingOne,
    failOnAdding: state.ContractConcept?.failOnAddingNewOne,
    failOnUpdating: state.ContractConcept?.failOnUpdatingOne,
    error: state.ContractConcept?.error,
    Contract: CurrentContract,
    Concept: CurrentConcept,
    Concepts: CurrentAddendum?.concepts?.map((concept: ContractConcept) => {
        const isConceptSelected = CurrentAddendum?.concepts?.find(
            (internalConcept: ContractConcept) => internalConcept.id === ownProps.ContractConceptId
          )?.concepts?.find(
              (conceptConcept: ContractConceptConcept) => conceptConcept.relatedContractConceptId === concept.id
              ) ? true : false;
        return { ...concept, isSelected: isConceptSelected, isUnselected: false }; 
    }),
  }
};

export const mapDispatchToProps = {
  ...ContractConceptStore.actionCreators,
  ...ContractStore.actionCreators,
  ...ContractConceptPaymentScheduleStore.actionCreators,
  ...ContractConceptConceptStore.actionCreators
};

export interface IValueOriginOption extends IDropdownOption {
  isFixedPrice?: boolean;
  isCostValue?: boolean;
  isPercentage?: boolean;
  isPriceList?: boolean;
  isDefinedByVisit?: boolean;
}

export interface ISettlementOption extends IDropdownOption {
  isIncludeInFirstSettlement?: boolean;
  isIncludeInLastSettlement?: boolean;
  isIncludeInEverySettlement?: boolean;
  isIdentifyVisit?: boolean;
  isIncludeInEveryStage?: boolean;
  isByPeriod?: boolean;
  isByPatient?: boolean;
  isByAddendum?: boolean;
  isManual?: boolean;
}


export const SettlementOptions: ISettlementOption[] = [
  { key: '1', text: 'SettlementOptions.FirstSettlement', 
    isIncludeInFirstSettlement: true, 
    isIncludeInLastSettlement: false, 
    isIncludeInEverySettlement: false, 
    isIdentifyVisit: false, 
    isByPeriod: false, 
    isIncludeInEveryStage: false,
    isByPatient: false,
    isByAddendum: false,
    isManual: false 
  },
  { key: '2', text: 'SettlementOptions.LastSettlement', 
    isIncludeInFirstSettlement: false, 
    isIncludeInLastSettlement: true, 
    isIncludeInEverySettlement: false, 
    isIdentifyVisit: false, 
    isByPeriod: false, 
    isIncludeInEveryStage: false,
    isByPatient: false,
    isByAddendum: false,
    isManual: false
  },
  { key: '3', text: 'SettlementOptions.EachSettlement', 
    isIncludeInFirstSettlement: false, 
    isIncludeInLastSettlement: false, 
    isIncludeInEverySettlement: true, 
    isIdentifyVisit: false, 
    isByPeriod: false, 
    isIncludeInEveryStage: false,
    isByPatient: false,
    isByAddendum: false,
    isManual: false
  },
  { key: '4', text: 'SettlementOptions.ByStage', 
    isIncludeInFirstSettlement: false, 
    isIncludeInLastSettlement: false, 
    isIncludeInEverySettlement: false, 
    isIdentifyVisit: true, 
    isByPeriod: false, 
    isIncludeInEveryStage: false,
    isByPatient: false,
    isByAddendum: false,
    isManual: false
  },
  { key: '6', text: 'SettlementOptions.AllStage', 
    isIncludeInFirstSettlement: false, 
    isIncludeInLastSettlement: false, 
    isIncludeInEverySettlement: false, 
    isIdentifyVisit: false, 
    isByPeriod: false, 
    isIncludeInEveryStage: true,
    isByPatient: false,
    isByAddendum: false,
    isManual: false
  },
  { key: '5', text: 'SettlementOptions.ByPeriod', 
    isIncludeInFirstSettlement: false, 
    isIncludeInLastSettlement: false, 
    isIncludeInEverySettlement: false, 
    isIdentifyVisit: false, 
    isByPeriod: true, 
    isIncludeInEveryStage: false,
    isByPatient: false,
    isByAddendum: false,
    isManual: false
  },
  { key: '7', text: 'SettlementOptions.ByPatient', 
    isIncludeInFirstSettlement: false, 
    isIncludeInLastSettlement: false, 
    isIncludeInEverySettlement: true, 
    isIdentifyVisit: false, 
    isByPeriod: false, 
    isIncludeInEveryStage: true,
    isByPatient: true,
    isByAddendum: false,
    isManual: false
  },
  { key: '8', text: 'SettlementOptions.Manual', 
    isIncludeInFirstSettlement: false, 
    isIncludeInLastSettlement: false, 
    isIncludeInEverySettlement: true, 
    isIdentifyVisit: false, 
    isByPeriod: true, 
    isIncludeInEveryStage: false,
    isByPatient: false,
    isByAddendum: false,
    isManual: true
  },
  { key: '9', text: 'SettlementOptions.ByAddendum', 
    isIncludeInFirstSettlement: false, 
    isIncludeInLastSettlement: false, 
    isIncludeInEverySettlement: true, 
    isIdentifyVisit: false, 
    isByPeriod: false, 
    isIncludeInEveryStage: false,
    isByPatient: false,
    isByAddendum: true,
    isManual: false
  }
];

export const PeriodOptions: IDropdownOption[] = [
  { key: '1', text: 'Mensual' },
  { key: '2', text: 'Bimestral' },
  { key: '3', text: 'Trimestral' },
  { key: '4', text: 'Semestral' },
  { key: '5', text: 'Anual' }
];

export const UnitMeasurementOptions: IDropdownOption[] = [
  { key: '1', text: 'Por concepto' },
  { key: '2', text: 'Por Hora' }
];

export const ValueOriginOptions: IValueOriginOption[] = [
  { key: '1', text: 'ValueOriginOptions.FixedValue', isFixedPrice: true, isPercentage: false, isPriceList: false, isDefinedByVisit: false, isCostValue: false },
  { key: '2', text: 'ValueOriginOptions.ValueByCost', isFixedPrice: false, isPercentage: false, isPriceList: false, isDefinedByVisit: false, isCostValue: true },
  { key: '3', text: 'ValueOriginOptions.ValueByPercentage', isFixedPrice: false, isPercentage: true, isPriceList: false, isDefinedByVisit: false, isCostValue: false },
  { key: '4', text: 'ValueOriginOptions.ValueByPriceList', isFixedPrice: false, isPercentage: false, isPriceList: true, isDefinedByVisit: false, isCostValue: false },
  { key: '5', text: 'ValueOriginOptions.ValueByStage', isFixedPrice: false, isPercentage: false, isPriceList: false, isDefinedByVisit: true, isCostValue: false }
];

export const PriceListOptions: IDropdownOption[] = [
  { key: '1', text: 'Lista de precio propia' },
  { key: '2', text: 'Lista de precio proveedor' },
  { key: '2', text: 'Lista de precio cobertura médica' }
];

export interface IPercentageOriginOption extends IChoiceGroupOption {
  takeFromConcepts?: boolean;
  takeFromPriceList?: boolean;
}

export const PercentageOriginOptions: IPercentageOriginOption[] = [
  { key: '1', text: 'En base a conceptos', takeFromConcepts: true, takeFromPriceList: false },
  { key: '2', text: 'En base a lista de precios', takeFromConcepts: false, takeFromPriceList: true }
];

export interface ICostOptions extends IDropdownOption {
  isFixedPrice?: boolean;
  isPercentage?: boolean;
  isProviderValue?: boolean;
  isPriceList?: boolean;
}

export const ConceptCostOptions: ICostOptions[] = [
  { key: '1', text: 'Valor fijo', isFixedPrice: true, isPercentage: false, isProviderValue: false, isPriceList: false },
  { key: '2', text: 'Valor Procentage del precio fijo', isFixedPrice: false, isPercentage: true, isProviderValue: false, isPriceList: false },
  { key: '4', text: 'Valor lista de precio', isFixedPrice: false, isPercentage: false, isProviderValue: false, isPriceList: true },
];

export const OriginConceptCostOptions: IDropdownOption[] = [
  { key: '1', text: 'Lista de precios propia' },
  { key: '2', text: 'Lista de precios de proveedor' },
];

export const calculateTypeOptions: IDropdownOption[] = [
  { key: '1', text: 'Por unidad' },
  { key: '2', text: 'Por turno' },
  { key: '3', text: 'Por visita' },
  { key: '4', text: 'Por hora' }
];

export const getCalculateTypeDescription = (calculateType: number | undefined): string => {
  switch (calculateType) {
    case (1): return 'unidades';
    case (2): return 'Turnos';
    case (3): return 'Visitas';
    case (4): return 'Horas';
    default: return '';
  }
}

import { MedicalProtocolsState, MedicalProtocolsUnloadState } from '../states/MedicalProtocolsState';

// MedicalProtocols Reducer
import { Action, Reducer } from 'redux';

// actions
import * as Actions from '../actions/';

// functions
import * as Functions from '../../functions/common';
import { Protocol } from '../../models/Entities/Protocols/Protocol';


type KnownAction = 
| Actions.MedicalProtocols.MedicalProtocols_GetAll_Request_Action 
| Actions.MedicalProtocols.MedicalProtocols_GetAll_Receive_Action 
| Actions.MedicalProtocols.MedicalProtocols_GetAll_Fail_Action
| Actions.MedicalProtocols.MedicalProtocols_UpdateAll_Request_Action
| Actions.MedicalProtocols.MedicalProtocols_UpdateAll_Receive_Action
| Actions.MedicalProtocols.MedicalProtocols_UpdateAll_Fail_Action
| Actions.MedicalProtocols.MedicalProtocols_GetById_Request_Action
| Actions.MedicalProtocols.MedicalProtocols_GetById_Receive_Action
| Actions.MedicalProtocols.MedicalProtocols_GetById_Fail_Action
| Actions.MedicalProtocols.MedicalProtocols_GetAll_ContractAssociations_Request_Action
| Actions.MedicalProtocols.MedicalProtocols_GetAll_ContractAssociations_Receive_Action
| Actions.MedicalProtocols.MedicalProtocols_GetAll_ContractAssociations_Fail_Action
| Actions.Contract.Contract_Associate_To_Protocol_Receive_Action
| Actions.Contract.Contract_Disassociate_From_Protocol_Receive_Action
| Actions.ProtocolPractitioners.ProtocolPractitiones_GetAll_Receive_Action
| Actions.MedicalProtocols.MedicalProtocols_GetAll_ProtocolPatients_Receive_Action
| Actions.ProtocolPractitioners.ProtocolPractitionesStudyTask_GetAll_Receive_Action
| Actions.MedicalProtocols.MedicalProtocols_GetAll_PatientVisit_ByProtocolId_Receive_Action
| Actions.MedicalProtocols.MedicalProtocols_GetAll_Visits_ByProtocolId_Receive_Action;

export const MedicalProtocolsReducer: Reducer<MedicalProtocolsState> = (
    state: MedicalProtocolsState | undefined, incomingAction: Action): MedicalProtocolsState => {
    
    if (state === undefined) {
        return MedicalProtocolsUnloadState as MedicalProtocolsState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'MEDICALPROTOCOLS_GET_ALL_REQUEST_ACTION':
            return {
                ...state,
                isLoadingAll: true,
                successLoadingAll: false,
                failOnLoadingAll: false,
                error: undefined
            };
        case 'MEDICALPROTOCOLS_GET_ALL_RECEIVE_ACTION':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: true,
                failOnLoadingAll: false,
                error: undefined,
                list: action.MedicalProtocols.sort(Functions.DynamicSort('title'))
            };
        case 'MEDICALPROTOCOLS_GET_ALL_FAIL_ACTION':
            return {
                ...state,
                isLoadingAll: false,
                successLoadingAll: false,
                failOnLoadingAll: true,
                error: action.error
            };
        case 'MEDICALPROTOCOLS_UPDATE_ALL_REQUEST_ACTION':
            return {
                ...state,
                isUpdatingAll: true,
                isUpdatingAllSuccess: undefined,
                FailUpdatingAll: false,
                error: undefined
            };
        case 'MEDICALPROTOCOLS_UPDATE_ALL_RECEIVE_ACTION':
            return {
                ...state,
                isUpdatingAll: false,
                isUpdatingAllSuccess: true,
                FailUpdatingAll: false,
                error: undefined,
                list: action.MedicalProtocols
            };
        case 'MEDICALPROTOCOLS_UPDATE_ALL_FAIL_ACTION':
            return {
                ...state,
                isUpdatingAll: false,
                isUpdatingAllSuccess: false,
                FailUpdatingAll: true,
                error: action.error
            };
        case 'MEDICALPROTOCOLS_GET_BY_ID_REQUEST_ACTION':
            return {
                ...state,
                isLoadingOne: true,
                successLoadingOne: false,
                failOnLoadingOne: false,
                error: undefined
            };
        case 'MEDICALPROTOCOLS_GET_BY_ID_RECEIVE_ACTION':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: true,
                failOnLoadingOne: false,
                error: undefined,
                list: (state.list ? [...state.list?.filter((item: any) => item.id !== action.MedicalProtocol.id), action.MedicalProtocol]: [action.MedicalProtocol])
            };
        case 'MEDICALPROTOCOLS_GET_BY_ID_FAIL_ACTION':
            return {
                ...state,
                isLoadingOne: false,
                successLoadingOne: false,
                failOnLoadingOne: true,
                error: action.error
            };
        case 'CONTRACT_ASSOCIATE_TO_PROTOCOL_RECEIVE_ACTION':
            var ProtocolWithContractAssociated = state.list?.find((item: Protocol) => item.id === action.contractProtocolAssociation.protocolId);
            if(ProtocolWithContractAssociated) {
                ProtocolWithContractAssociated = {...ProtocolWithContractAssociated, contractAssociation : action.contractProtocolAssociation};
            }
            return {
                ...state,
                list: (ProtocolWithContractAssociated ? [...state.list?.filter((item: any) => item.id !== action.contractProtocolAssociation.protocolId), ProtocolWithContractAssociated] : state.list).sort(Functions.DynamicSort('title'))
            };
        case 'CONTRACT_DISASSOCIATE_FROM_PROTOCOL_RECEIVE_ACTION':
            var ProtocolWithContractDisassociated = state.list?.find((item: Protocol) => item.id === action.ProtocolId);
            if(ProtocolWithContractDisassociated) {
                ProtocolWithContractDisassociated = {...ProtocolWithContractDisassociated, contractAssociation : undefined};
            }
            return {
                ...state,
                list: (ProtocolWithContractDisassociated ? [...state.list?.filter((item: any) => item.id !== action.ProtocolId), ProtocolWithContractDisassociated] : state.list).sort(Functions.DynamicSort('title'))
            };
        case 'MEDICALPROTOCOLS_GETALL_CONTRACTASSOCIATIONS_REQUEST_ACTION':
            return {
                ...state,
                isGettingProtocolContractAssociation: true,
                isGettingProtocolContractAssociationSuccess: undefined,
                FailGettingProtocolContractAssociation: false,
                error: undefined
            };
        case 'MEDICALPROTOCOLS_GETALL_CONTRACTASSOCIATIONS_RECEIVE_ACTION':
            return {
                ...state,
                isGettingProtocolContractAssociation: false,
                isGettingProtocolContractAssociationSuccess: true,
                FailGettingProtocolContractAssociation: false,
                list: state.list?.map((protocol: Protocol) => {
                    if(protocol.id === action.ProtocolId) {
                        return {...protocol, contractAssociation : action.contractAssociation};
                    } else {
                        return protocol;
                    }
                }),
                error: undefined
            };
        case 'MEDICALPROTOCOLS_GETALL_CONTRACTASSOCIATIONS_FAIL_ACTION':
            return {
                ...state,
                isGettingProtocolContractAssociation: false,
                isGettingProtocolContractAssociationSuccess: false,
                FailGettingProtocolContractAssociation: true,
                error: action.error
            };
        case 'PROTOCOL_PRACTITIONERS_GETALL_RECEIVE_ACTION':
            return {
                ...state,
                list: state.list?.map((protocol: Protocol) => {
                    if(protocol.id === action.protocolId) {
                        return {...protocol, practitioners : action.protocolPractitioners};
                    } else {
                        return protocol;
                    }
                })
            };
        case 'PROTOCOL_PRACTITIONERS_STUDYTASK_GETALL_RECEIVE_ACTION':
            return {
                ...state,
                list: state.list?.map((protocol: Protocol) => {
                    if(protocol.id === action.protocolId) {
                        return {...protocol, practitionersStudyTask : action.protocolPractitioners};
                    } else {
                        return protocol;
                    }
                })
            };
        case 'MEDICALPROTOCOLS_GETALL_PROTOCOLPATIENTS_RECEIVE_ACTION':        
            return {
                ...state,
                list: state.list?.map((protocol: Protocol) => {
                    if(protocol.id === action.ProtocolId) {
                        return {...protocol, patients : action.ProtocolPatients};
                    } else {
                        return protocol;
                    }
                })
            };
        case 'MEDICALPROTOCOLS_GETALL_PATIENTVISIT_BYPROTOCOLID_RECEIVE_ACTION':
            return {
                ...state,
                list: state.list?.map((protocol: Protocol) => {
                    if(protocol.id === action.ProtocolId) {
                        return {...protocol, patientVisits : action.visits};
                    } else {
                        return protocol;
                    }
                })
            };
        case 'MEDICALPROTOCOLS_GETALL_VISITS_BYPROTOCOLID_RECEIVE_ACTION':
            return {
                ...state,
                list: state.list?.map((protocol: Protocol) => {
                    if(protocol.id === action.ProtocolId) {
                        return {...protocol, visits : action.visits};
                    } else {
                        return protocol;
                    }
                })
            };
        default:
            return state;
    }
};